export const getDiscountAmount = (salesPrice: number, basePrice: number): number | null => {
  if (!basePrice || salesPrice >= basePrice)
    return null;

  return Math.round((basePrice - salesPrice) * 100) / 100;
};

export const getDiscountPercentage = (salesPrice: number, basePrice: number): number | null => {
  if (!basePrice || salesPrice >= basePrice)
    return null;

  if (!salesPrice)
    return 100;

  const actualPercent = (salesPrice * 100) / basePrice;
  return Math.round((100 - actualPercent) * 100) / 100;
};

export const getM2DiscountAmount = (m2SalesPrice: number, m2BasePrice: number): number | null => {
    if (!m2BasePrice || m2SalesPrice >= m2BasePrice)
        return null;

    return Math.round((m2BasePrice - m2SalesPrice) * 100) / 100;
};

export const getM2DiscountPercentage = (m2SalesPrice: number, m2BasePrice: number): number | null => {
    if (!m2BasePrice || m2SalesPrice >= m2BasePrice)
        return null;

    if (!m2SalesPrice)
        return 100;

    const actualPercent = (m2SalesPrice * 100) / m2BasePrice;
    return Math.round((100 - actualPercent) * 100) / 100;
};

import type { ActionPricesPresentationType } from 'behavior/settings';
import { getDiscountAmount, getDiscountPercentage, getM2DiscountAmount, getM2DiscountPercentage } from './discountUtils';

export const createPriceModel = (salesPrice: number, basePrice: number, actionPricesMode: ActionPricesPresentationType) => {
    switch (actionPricesMode) {
    case 'SALES_PRICE':
      return {
        price: salesPrice,
      };

    case 'BASE_AND_SALES_PRICE':
      return {
        price: salesPrice,
        actionPrice: basePrice,
      };

    case 'BASE_AND_SALES_PRICE_DISCOUNT_PERCENTAGE':
      return {
        price: salesPrice,
        actionPrice: basePrice,
        discountPercentage: getDiscountPercentage(salesPrice, basePrice),
      };

    case 'BASE_AND_SALES_PRICE_DISCOUNT_AMOUNT':
      return {
        price: salesPrice,
        actionPrice: basePrice,
        discountAmount: getDiscountAmount(salesPrice, basePrice),
      };

    case 'SALES_PRICE_DISCOUNT_AMOUNT':
      return {
        price: salesPrice,
        discountAmount: getDiscountAmount(salesPrice, basePrice),
      };

    case 'SALES_PRICE_DISCOUNT_PERCENTAGE':
      return {
        price: salesPrice,
        discountPercentage: getDiscountPercentage(salesPrice, basePrice),
      };

    default:
      throw new Error(`Action prices presentation mode ${actionPricesMode} is not supported.`);
  }
};

export const createM2PriceModel = (m2SalesPrice: number, m2BasePrice: number, actionPricesMode: ActionPricesPresentationType) => {
    switch (actionPricesMode) {
        case 'SALES_PRICE':
            return {
                m2rice: m2SalesPrice,
            };

        case 'BASE_AND_SALES_PRICE':
            return {
                m2Price: m2SalesPrice,
                m2ActionPrice: m2BasePrice,
            };

        case 'BASE_AND_SALES_PRICE_DISCOUNT_PERCENTAGE':
            return {
                m2rice: m2SalesPrice,
                m2ActionPrice: m2BasePrice,
                m2DiscountPercentage: getM2DiscountPercentage(m2SalesPrice, m2BasePrice),
            };

        case 'BASE_AND_SALES_PRICE_DISCOUNT_AMOUNT':
            return {
                m2rice: m2SalesPrice,
                m2ActionPrice: m2BasePrice,
                m2DiscountAmount: getM2DiscountAmount(m2SalesPrice, m2BasePrice),
            };

        case 'SALES_PRICE_DISCOUNT_AMOUNT':
            return {
                m2rice: m2SalesPrice,
                m2DiscountAmount: getM2DiscountAmount(m2SalesPrice, m2BasePrice),
            };

        case 'SALES_PRICE_DISCOUNT_PERCENTAGE':
            return {
                m2rice: m2SalesPrice,
                m2DiscountPercentage: getM2DiscountPercentage(m2SalesPrice, m2BasePrice),
            };

        default:
            throw new Error(`Action prices presentation mode ${actionPricesMode} is not supported.`);
    }
};

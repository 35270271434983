import styles from './NewsletterSubscription.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { SimpleText, RichText, useSimpleTexts } from 'components/sanaText';
import { subscribeToNewsletter } from 'behavior/newsletter';
import { SanaForm, FieldError, CaptchaFormGroup, useCaptchaOnSubmit } from 'components/objects/forms';
import { EmailAddressField } from 'components/objects/forms/fields';
import { SanaButton } from 'components/primitives/buttons';
import { useSuccessMessage } from './hooks';
import { useHasAbilities } from 'components/objects/user';
import { AbilityTo } from 'behavior/user/constants';
import { FormName } from 'behavior/pages';
import loadable from '@loadable/component';

const DesignerModeCaptcha = loadable(() => import(/*webpackChunkName:"vd"*/'components/primitives/captcha'), { resolveComponent: m => m.DesignerModeCaptcha });

const NewsletterSubscription = ({ id, model, isDesignerMode }) => {
  const dispatch = useDispatch();
  const { lastUpdated, updatedById } = useSelector(state => state.newsletter);
  const formName = `${FormName.NewsletterSubscribe}_${id}`;
  const subscribedByComponent = formName === updatedById;
  const [emailTitle] = useSimpleTexts(['EmailAddress']).texts;
  const onBlur = (e, formik) => !e.currentTarget.contains(e.relatedTarget || document.activeElement) && formik.setFieldError('email', undefined);
  const { onSubmit, captchaRef } = useCaptchaOnSubmit(formData => {
    dispatch(subscribeToNewsletter(formData, formName));
  }, FormName.NewsletterSubscribe, []);

  useSuccessMessage(lastUpdated, subscribedByComponent, 'NewsletterSubscribedSuccessfully');

  return (
    <div className={styles.container}>
      {model.showTitle &&
        <p className={`${styles.title} h4`}>
          <SimpleText textKey="NewsletterContainer_Title" />
        </p>
      }
      {model.showDescription &&
        <div className={styles.description}>
          <RichText textKey="NewsletterContainer_Description" />
        </div>
      }
      <SanaForm
        name={formName}
        onSubmit={onSubmit}
        shouldReset={subscribedByComponent}
        validateOnBlur={false}
        onBlur={onBlur}
      >
        <div className={styles.mainForm}>
          <div className={styles.field}>
            <EmailAddressField
              fieldName="email"
              fieldTitle={emailTitle}
              placeholder={emailTitle}
              maxLength={80}
              size="12"
              required
              validation={{ required: true }}
              disabled={isDesignerMode}
            />
          </div>
          <SanaButton textKey="Subscribe" type="submit" className={`${btnStyles.btnSmall} ${styles.btnSubmit} ${isDesignerMode && styles.vdMode}`} disabled={isDesignerMode} />
          <div className={styles.validation}>
            <FieldError fieldName="email" />
          </div>
        </div>
        {isDesignerMode
          ? <DesignerModeCaptcha className={styles.recaptcha} />
          : <CaptchaFormGroup ref={captchaRef} captchaId={formName} className={styles.recaptcha} formGroupClassName={styles.noLabel} />
        }
      </SanaForm>
    </div>
  );
};

NewsletterSubscription.propTypes = {
  id: PropTypes.string.isRequired,
  model: PropTypes.shape({
    showTitle: PropTypes.bool.isRequired,
    showDescription: PropTypes.bool.isRequired,
  }).isRequired,
  isDesignerMode: PropTypes.bool,
};

// eslint-disable-next-line react/no-multi-comp
const NewsletterSubscriptionBlock = ({ isDesignerMode, ...props }) => {
  const [canSubscribe] = useHasAbilities(AbilityTo.SubscribeToNewsletter);

  if (!isDesignerMode && !canSubscribe)
    return null;

  return <NewsletterSubscription isDesignerMode={isDesignerMode} {...props} />;
};

NewsletterSubscriptionBlock.propTypes = {
  id: PropTypes.string.isRequired,
  model: PropTypes.shape({
    showTitle: PropTypes.bool.isRequired,
    showDescription: PropTypes.bool.isRequired,
  }).isRequired,
  isDesignerMode: PropTypes.bool,
};

export default NewsletterSubscriptionBlock;

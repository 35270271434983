import createItemList from './itemList';
import createProductImages, { ProductWithImages } from './productImages';
import { createUrl } from '../url';
import { ProductTrackingOptions } from './types';

interface Product extends ProductWithImages {
    url: string | null;
    title: string | null;
    pricePerMeterSquare: number | null;
}

export default function (products: Product[], options: ProductTrackingOptions) {
    const { origin } = options;

    return createItemList(products.map(product => {
        const { url, title: name, pricePerMeterSquare } = product;
        return {
            name,
            url: createUrl(url, origin),
            pricePerMeterSquare: pricePerMeterSquare?.toFixed(2),
            ...createProductImages(product, options),
        };
    }));
}
